body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: #f7f9fd;
}

.progress {
  border-radius: 0;
}

.btn-chart-range {
  margin-right: 5px;
}

.flex-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 30px 0 10px 0;
}

.stats-overview {
  min-height: 100px;
  text-align: center;
  padding: 10px;
  background-color: #e5e5e5;
  border-radius: 10px;
  margin-bottom: 10px;
}

.high-level-overview {
  background-color: #79c666;
}

.stats-overview .icon {
  background-color: #333333;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
}

.stats-overview .icon img {
  color: #fff;
  width: 27px;
  height: 27px;
}

.stats-overview .title {
  margin-top: 10px;
  color: #9da3ae;
  font-weight: bold;
}

.high-level-overview .title {
  color: #fff;
}

.stats-overview .count {
  margin-top: 3px;
  font-size: 17px;
  font-weight: bold;
}

.high-level-overview .count {
  font-size: 20px;
}

.overview-legend {
  margin-top: 10px;
  font-size: 15px;
  color: #111;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .stats-overview {
    width: 110px;
  }
  .high-level-overview {
    width: 160px;
  }
}

@media only screen and (min-width: 600px) {
  .stats-overview {
    min-width: 160px;
  }
  .high-level-overview {
    min-width: 200px;
  }
}

div[data-cy='option-default'] {
  background-color: #8bc471 !important;
  color: #fff !important;
}
